import React, {Component} from "react";
import {Grid, withStyles} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {compose} from "redux";
import customInputField from "../../../atoms/CustomInputField";
import cn from "classnames";
import styles from "../styles.jsx";
import Header from "../../../molecules/AuthV2/Header";
import utilCommon, {isFloat} from "../../../../helpers/utilCommon";
import Loader from "../../../atoms/Loader";
import MaskedInput from "../../../atoms/MaskedInput";
import lodash from "lodash";
import {EXIT_POP_UP_CONTENT_ID, SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS,} from '../../../../helpers/enums'
import ExitPopUp from '../../../organisms/ExitPopUp'
import DropdownSelect from "../../../atoms/NewUI/DropdownSelect";
import logo from '../../../../assets/images/logo-new.svg'
import trackingIcon from '../../../../assets/images/tracking_icon.png'
import calendarIcon from '../../../../assets/images/calendar_icon.png'
import clockIcon from '../../../../assets/images/clock_icon.png'
import trophyIcon from '../../../../assets/images/trophy_icon.png'

const labels = ['First name', 'Last name', 'Email', 'Phone', 'Estimated shipments per month'];

const validate = formProps => {
    const errors = {};
    const requiredFields = ['customerFirstName', 'customerLastName', 'customerEmailId', 'phone', 'estimatedShipmentsPerMonth']
    requiredFields.map((x, i) => {
        if (!formProps[x]) {
            errors[x] = labels[i] + ' is a required field';
        }
        if (formProps[x] && formProps[x].length === 0) {
            errors[x] = labels[i] + ' is a required field';
        }
    })

    if (formProps.customerEmailId && !utilCommon.Email_Regex.test(formProps.customerEmailId)) {
        errors.customerEmailId = 'Please enter a valid email address'
    }

    if (!formProps.phone || formProps.phone.length != 10) {
        errors.formatedPhone = 'Please enter valid phone number';
    }

    if (
        formProps.estimatedShipmentsPerMonth &&
        (isFloat(Number(formProps.estimatedShipmentsPerMonth)) || Number(formProps.estimatedShipmentsPerMonth) < 1)
    ) {
        errors.estimatedShipmentsPerMonth = 'Must be positive integer number';
    }

    return errors;
}

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerType: SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS,
            showPassword: false,
            showOnExitPopUp: false,
            isExitPopUpWasShown: false,
            clientY: null,
            step: 1
        }

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.checkClientY = this.checkClientY.bind(this);

        this.timer = null;
    }

    setPhone = (e) => {
        this.props.change(`phone`, e.target.value.replace(/[^\d]/g, ''));
    }

    componentDidMount() {
        this.props.changeDialogClass('extraSmall')
        document.addEventListener('mousemove', this.handleMouseMove);
        requestAnimationFrame(this.checkClientY);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseMove(event) {
        this.setState({clientY: event.clientY});
    }

    checkClientY() {
        if (this.state.isExitPopUpWasShown) {
            document.removeEventListener('mousemove', this.handleMouseMove);
            return;
        }

        if (lodash.isEmpty(this.props.popUpContentList)) {
            requestAnimationFrame(this.checkClientY);
            return;
        }

        const {clientY} = this.state;

        if (clientY !== null && clientY <= 10) {
            this.setState({
                showOnExitPopUp: true,
                isExitPopUpWasShown: true,
            })
        }

        requestAnimationFrame(this.checkClientY);
    }

    getExitPopUpContent = () => {
        if (
            !this.props.popUpContentList ||
            !this.props.popUpContentList.count ||
            !this.props.popUpContentList.data
        ) {
            return null
        }

        const exitPopUp = this.props.popUpContentList.data
            .find(el => el.id === EXIT_POP_UP_CONTENT_ID)

        if (!exitPopUp || exitPopUp.status !== 1) {
            return null
        }

        return exitPopUp.content
    }

    handleCloseOnExitPopUp = () => {
        this.setState({
            showOnExitPopUp: false,
        });
    }

    prevStep = () => {
        this.props.changeDialogClass('extraSmall')
        this.setState({step: 1})
    }

    nextStep = () => {
        this.props.changeDialogClass('mediumSmall')
        this.setState({step: 2})
        utilCommon.sendPinpointEvent(
            this.props.formProps.customerEmailId,
            {
                isUnregisteredCustomer: ['true']
            },
            'firstStepRegistration'
        )
    }

    render() {
        const {classes, switchForm, handleSubmit, valid, submitting, loading, shipmentFreq} = this.props;

        return (
            <form name="SignUpForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)} className="signupFormV2">
                <Header switchForm={switchForm} switchTo={'login'} text={'Already have an account?'}
                        buttonText={'Sign In'}/>
                { this.state.step === 1 &&
                    <DialogContent className={classes.contentPadding}>
                        <Grid container justify='left'>
                            <Grid item>
                                <DialogContentText className={cn(classes.contentTitleText, 'contentTitleText')}>
                                    Welcome to FreightCenter
                                </DialogContentText>
                            </Grid>
                            <Grid container className={cn(classes.inputContainer, 'inputContainer')} spacing={8}>
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.inputMargin}>
                                    <div className={classes.inputLabelText}>Email Address</div>
                                    <Field
                                        name={'customerEmailId'}
                                        component={customInputField}
                                        disableUnderline
                                        classes={classes}
                                        style={{marginBottom: 7}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mb16}>
                                <Button disabled={!valid}
                                        className={cn(classes.headerButton, classes.submitButton, 'submitButton')}
                                        type="submit"
                                        variant="contained"
                                        onClick={this.nextStep}>
                                    Get Quote
                                </Button>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <img src={trackingIcon} width={24} height={24}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className={cn(classes.itemText, 'itemText')}>
                                                97% on-time pick-ups and deliveries
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <img src={calendarIcon} width={24} height={24}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className={cn(classes.itemText, 'itemText')}>
                                                Book Shipments Online 24/7
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <img src={clockIcon} width={24} height={24}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className={cn(classes.itemText, 'itemText')}>
                                                Track your shipments
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={1}>
                                            <img src={trophyIcon} width={24} height={24}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <div className={cn(classes.itemText, 'itemText')}>
                                                Earn great prizes with our Rewards Program
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <DialogContentText className={cn(classes.termsAndConditionsText, 'termsAndConditionsText')}>
                                        By proceeding, you agree to our <a
                                        href="https://www.freightcenter.com/privacy-policy" target="_blank">Privacy
                                        Policy</a> and <a
                                        href={'https://www.freightcenter.com/terms-and-conditions'}
                                        target="_blank">Terms & Conditions</a>.
                                        Account creation enrolls you to receive communications about shipments
                                        status and
                                        promotions through email and SMS.
                                        You may manage communications through your Account Settings page.
                                    </DialogContentText>
                                </Grid>
                            </Grid>
                            {loading && <Loader/>}
                        </Grid>
                    </DialogContent>
                }
                { this.state.step === 2 &&
                    <DialogContent className={cn(classes.contentPadding, 'nextStep')}>
                        <div className={classes.leftArrow} onClick={this.prevStep}>⇦</div>
                        <Grid container direction={utilCommon.isMobile() ? 'column-reverse' : 'row'}>
                            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.contentLeftColumn}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className={classes.logo}>
                                            <img src={logo}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <img src={trackingIcon} width={24} height={24}/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div className={classes.itemText}>
                                                    97% on-time pick-ups<br/>and deliveries
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <img src={calendarIcon} width={24} height={24}/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div className={classes.itemText}>
                                                    Book Shipments Online 24/7
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <img src={clockIcon} width={24} height={24}/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div className={classes.itemText}>
                                                    Track your shipments
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <img src={trophyIcon} width={24} height={24}/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div className={classes.itemText}>
                                                    Earn great prizes with our<br/>Rewards Program
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <DialogContentText className={classes.termsAndConditionsText}>
                                            By proceeding, you agree to our <a
                                            href="https://www.freightcenter.com/privacy-policy" target="_blank">Privacy
                                            Policy</a> and <a
                                            href={'https://www.freightcenter.com/terms-and-conditions'}
                                            target="_blank">Terms & Conditions</a>.
                                            Account creation enrolls you to receive communications about shipments
                                            status and
                                            promotions through email and SMS.
                                            You may manage communications through your Account Settings page.
                                        </DialogContentText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7} className={classes.contentRightColumn}>
                                <Grid container>
                                    <Grid item>
                                        <DialogContentText className={classes.contentTitleText2}>
                                            Save up to 70% on Shipping
                                        </DialogContentText>
                                    </Grid>
                                    <Grid container className={classes.inputContainer} spacing={8}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>Email Address</div>
                                            <div className={classes.inputDiv}>{this.props.formProps.customerEmailId}</div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>First Name</div>
                                            <Field
                                                name={'customerFirstName'}
                                                component={customInputField}
                                                disableUnderline
                                                classes={classes}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>Last Name</div>
                                            <Field
                                                name={'customerLastName'}
                                                component={customInputField}
                                                disableUnderline
                                                classes={classes}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>Company Name (optional)</div>
                                            <Field
                                                name={'customerCompany'}
                                                component={customInputField}
                                                disableUnderline
                                                classes={classes}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>Phone number</div>
                                            <Field
                                                requiredClass
                                                type="tel"
                                                maxLength={15}
                                                name="formatedPhone"
                                                onChange={this.setPhone}
                                                component={MaskedInput}
                                                inputProps={{currentMask: 'phone'}}
                                                classes={classes}
                                                disableUnderline
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className={classes.inputMargin}>
                                            <div className={classes.inputLabelText}>Shipments per month</div>
                                            <Field
                                                requiredClass
                                                name={'estimatedShipmentsPerMonth'}
                                                options={shipmentFreq}
                                                component={DropdownSelect}
                                                disableUnderline
                                                classes={classes}
                                                placeholder={''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.mb16}>
                                        <Button disabled={!valid || submitting}
                                                className={cn(classes.headerButton, classes.submitButton)} type="submit"
                                                variant="contained">
                                            Get Quote
                                        </Button>
                                    </Grid>
                                    {loading && <Loader/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }
                <ExitPopUp
                    open={this.state.showOnExitPopUp}
                    onClose={this.handleCloseOnExitPopUp}
                    exitPopUpContent={this.getExitPopUpContent()}
                />
            </form>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.common.loading,
    initialValues: {
        customerType: SIGN_UP_FORM_CUSTOMER_TYPE_BUSINESS,
    },
    formProps: state.form['SignUpForm'] ? state.form['SignUpForm'].values : {},
    popUpContentList: state.common.masterData ? state.common.masterData.popUpContentList : {},
    shipmentFreq: [{id: 2, name: '1-time'}, {id: 3, name: '1-10 / month'}, {id: 4, name: '10+ / month'}]
})

const mapDispatchToProps = dispatch => ({});

const component = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'SignUpForm',
        validate,
        enableReinitialize: true,
        touchOnChange: true
    }),
    withStyles(styles)
)(SignUpForm)

export default component;
